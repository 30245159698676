import {React, useState, useCallback} from "react";
import './app.css'

const mod = (n, m) => ((n % m) + m) % m;

const ZikrList = ({ info }) => {
    const [index, setIndex] = useState(0);

    const next = useCallback(() => 
      setIndex(state => mod(state + 1, info.length))
    , [setIndex, info]);
    
    const prev = useCallback(() => 
      setIndex(state => mod(state - 1, info.length))
    , [setIndex, info]);

    return (
        <div className="tasbeeh-container pt-5">
            <div className="px-3">
                <h1 className="zikr-arabic arabic-bold-display display-1">{info[index].arabic}</h1>
                <h3 className="zikr-english">{info[index].english}</h3>
                <h5 className="zikr-description pt-2 pb-1">{info[index].desc}</h5>
            </div>
            <div className="px-1">
                <button className="btn btn-dark btn-lg mt-4 float-end" onClick={next}>Next</button>
                <button className="btn btn-dark btn-lg mt-4 me-3 float-end" onClick={prev}>Previous</button>
            </div>
        </div>
    )
}

export default ZikrList;