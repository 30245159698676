import React from "react";
import './app.css';

const Navbar = () => {
    return (
        <>
            <nav className="navbar fixed-top navbar-expand-sm bg-dark navbar-dark py-3">
                <div className="container">
                    <a href="https://quranrc.com/" className="navbar-brand">QRC - Tasbeeh </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navmenu"
                    >
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navmenu">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item pe-4">
                            <a href="https://quranrc.com/#juz-select" className="nav-link">Read</a>
                        </li>
                        <li className="nav-item pe-4">
                            <a href="https://quranrc.com/#surah-section" className="nav-link">Surahs</a>
                        </li>
                        <li className="nav-item pe-4">
                            <a
                                href="https://www.youtube.com/channel/UCDp-6IjyN7nbZhpAgjzIhLQ"
                                target="_blank"
                                rel="noreferrer"
                                className="nav-link"
                                >QRC YouTube
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a href="" className="nav-link droptown-toggle" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                More
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                                <li><a className="dropdown-item" href="#top">Tasbeeh</a></li>
                            </ul>
					    </li>
                    </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;