import React from "react";
import './app.css'

const HowItWorks = () => {
    return (
        <div className="container tasbeeh-container how-container">
            <h1 className="display-5 fw-bold">How it works</h1>
            <h3>Click or press on the box containing the tasbeeh counter to increment the counter (If on Mac/PC, you can also press <span className="fw-bold">SPACE BAR</span>).</h3>
            <h3>Click or press <span className="fw-bold">Undo</span> to decrement the counter.</h3>
            <h3>Click or press <span className="fw-bold">Reset Tasbeeh</span> to reset the counter to Zero.</h3>
            <h3>Click or press <span className="fw-bold">Next</span> or <span className="fw-bold">Previous</span> to cycle through different Dhikr you can recite.</h3>
        </div>
    )
}

export default HowItWorks;