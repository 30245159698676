import React from "react";
import Navbar from "./Navbar";
import Counter from "./Counter";
import ZikrList from "./ZikrList";
import data from "./data";
import HowItWorks from "./HowItWorks";
import './app.css'

const App = () => {

    return (
        <>
            <Navbar />
            <div className="container content-container pt-5 pb-4">
                <Counter />
                <ZikrList {...data}/>
                <HowItWorks />
            </div>
            
        </>
    );
}

export default App;