let data = {
    "info": [
        {
            arabic: "الله أكبر",
            english: 'Allah is the greatest',
            desc: "Allahu-Akbar is called 'Takbir', and it is the most frequent form of Dhikr used by muslims in our daily lives. Most notably we hear it as a part of the Athan (call to prayer) and Salah (prayer)."
        },
        {
            arabic: "ٱلْحَمْدُ لِلَّٰهِ",
            english: 'All praise be to Allah',
            desc: "Alhumdulillah is called 'Tahmid', and it should be used whenever we want to express our graditude to Allah. It is also the first part of Ayah number 1 of Surah Fatihah, the first surah of the Quran."
        },
        {
            arabic: "سُـبْـحَـانَ ٱلله",
            english: 'Glory be to Allah',
            desc: "Subhanallah is called 'Tasbih', and is a form of Dhikr that should be used not only to glorify Allah during good times, but also during personal trial/struggle and as a remembrance for our purpose."
        },
        {
            arabic: "أَسْتَغْفِرُ اللّٰهَ",
            english: 'I seek forgiveness from Allah',
            desc: "Astaghfirullah is a form of 'Istighfar', which is the act of seeking forgiveness. It is recommended to recite this at least 100 times every day, as it is sunnah of the Prophet Muhammad ﷺ."
        },
        {
            arabic: "صَلَّى اللّٰهُ عَلَيْهِ وَسَلَّمَ", 
            english: "Peace and blessings be upon him",
            desc: "Sallallahu Alayhi Wa Sallam should be announced any time we hear the name of the Prophet Muhammad ﷺ. However, it can also be recited on its own as a form of dhikr."
        },
        {
            arabic: "لَا إِلَٰهَ إِلَّا الله",
            english: "There is no God but Allah",
            desc: "La ilaha illallah is the first part of our Shahadah (declaration of faith), and is the first of the 5 pillars of Islam. Saying it, we deny the worship and partnership of any God(s) except for Allah."
        },
        {
            arabic: "الله",
            english: "Allah",
            desc: "Allah comes from the root words 'Al' which means 'The' and 'Elah' which means 'God'. So Allah does not simply refer to 'a God', but it actually refers to 'The God' or 'the One and Only God'."
        }
    ]
}

export default data;

