import {React, useState} from "react";
import './app.css'

const Counter = () => {
    const [counter, setCounter] = useState(0);

    const increase = () => {
        setCounter(count => count + 1);
    }

    const decrease = () => {
        if (counter > 0) {
            setCounter(count => count - 1);
        }
    }

    const reset = () => {
        setCounter(0);
    }

    document.body.onkeyup = function(e) {
        if (e.key === " " ||
            e.code === "Space"
        ) {
            increase();
        }
      }

    return (
        <div className="tasbeeh-container">
            <div onClick={increase}>
                <h1 className="count">{counter}</h1>
            </div>
            <div>
                <button className="btn btn-dark btn-lg ms-1" onClick={decrease}>Undo</button>
                <button className="btn btn-dark btn-lg ms-3" onClick={reset}>Reset Tasbeeh</button>
            </div>
        </div>
    )
}

export default Counter;